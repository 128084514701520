
import { Component, Vue } from "vue-property-decorator";
import { IBackOptions } from "@/models/BackOptions";
import { IAddress } from "@/models/Address";
import { resolveRoutePath } from "@/router";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import PropertyPageLayout from "@/components/layout/PropertyPageLayout.vue";
import {
  IAccountingInAYear,
  IAccountingYears,
} from "@/models/IPropertyAccounting";
import ContactPerson from "@/components/ContactPerson.vue";
import { IPropertyInfo } from "@/models/PropertyInfo";
import {
  IRentalLeasings,
  IRentalLeasing,
  IRentalLeasingFormatted,
} from "@/models/RentalLeasing";
import DateUtils from "@/services/utils/DateUtils";
import AccountingShimmer from "@/components/AccountingShimmer.vue";
import AccountingMainSection from "@/components/AccountingMainSection.vue";
import AccountingMonthlyCardsList from "@/components/AccountingMonthlyCardsList.vue";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import ContactService from "@/services/ContactService";
import LeasingsService from "@/services/LeasingsService";
import IOption from "@/models/IOption";

@Component({
  components: {
    HousfyPage,
    PropertyPageLayout,
    ContactPerson,
    AccountingShimmer,
    AccountingMainSection,
    AccountingMonthlyCardsList,
  },
})
export default class Accounting extends Vue {
  propertyUuid: string = this.$route.params?.propertyUuid as string;

  leasings: IRentalLeasings | null = null;
  accountingInAYear: IAccountingInAYear | null = null;
  accountingYears: IAccountingYears | null = null;
  accountingYear: number | null = null;
  errorMessage: string | null = null;
  propertyInfo: IPropertyInfo | null = null;
  address: IAddress | null = null;
  loading = false;
  accountingYearsOptions: IOption<number>[] = [];

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }

  get headerTitle(): string {
    return this.address?.fullAddress || "";
  }

  get showContactPerson(): boolean {
    return ContactService.shouldShowContactPerson(
      this.propertyInfo,
      this.currentLeasing
    );
  }

  get currentLeasing(): IRentalLeasingFormatted | undefined {
    const currentLeasing = LeasingsService.getCurrentLeasing(
      this.leasings?.items
    );
    let currentLeasingFormatted;
    if (currentLeasing) {
      currentLeasingFormatted = {
        ...currentLeasing,
        periodDate: this.leasingFormatDate(currentLeasing),
      };
    }
    return currentLeasingFormatted;
  }

  leasingFormatDate(leasing: IRentalLeasing | undefined): string {
    if (!leasing) return "";
    const { leasingContractValidityDate, leasingTerminationDate } = leasing;
    const startDate = leasingContractValidityDate
      ? this.formatDate(leasingContractValidityDate)
      : "";
    const endDate = leasingTerminationDate
      ? this.formatDate(leasingTerminationDate)
      : this.$t("common.present");
    return `${startDate} - ${endDate}`;
  }

  formatDate(stringDate: string): string {
    const { day, month, year } = DateUtils.formatDate(stringDate);
    const monthShort = this.$t("common." + month + "Short");
    return `${day} ${monthShort} ${year}`;
  }

  async getYearAccounting(year: string | number): Promise<void> {
    try {
      const response = await api
        .property()
        .retrieveAccountingInAYear(this.propertyUuid, +year);

      const responseWithInvoiceArray = {
        ...response,
        items: response.totalPropertySummary.months.map((month) => ({
          ...month,
        })),
      };
      this.accountingInAYear = responseWithInvoiceArray;
    } catch (error) {
      this.errorMessage = ErrorService.getErrorFromCode(error);
    }
  }
  async loadInitialData() {
    this.loading = true;

    try {
      [this.address, this.propertyInfo, this.accountingYears, this.leasings] =
        await Promise.all([
          api.property().retrieveAddress(this.propertyUuid),
          api.property().retrieveInfo(this.propertyUuid),
          api.property().retrieveAccountingYears(this.propertyUuid),
          api.property().retrieveLeasings(this.propertyUuid),
        ]);

      this.accountingYear = this.accountingYears.items[0];
      this.accountingYears?.items.forEach((year: number) => {
        if (year >= 2024) {
          this.accountingYearsOptions.push({
            label: year.toString(),
            value: year,
          });
        }
      });
    } catch (error) {
      this.errorMessage = ErrorService.getErrorFromCode(error);
    }

    if (this.accountingYear) await this.getYearAccounting(this.accountingYear);

    this.loading = false;
  }

  async mounted() {
    await this.loadInitialData();
  }
}
